<template>
    <div class="UpdateValueWizardStep02">
        <b-container class="import-wizard-step01">
          <b-row no-gutters>
            <b-col sm="12" md="4" lg="5" class="detail-text text-left pt-3 pb-2 px-2 py-md-5 pl-md-0 pr-md-3 py-lg-5 px-lg-5" align-self="start">
              <h2 class="text-dark">{{ $t(this.translationPath + 'heading') }} </h2>
              <div v-html="$t(translationPath + 'intro_text')"></div>
            </b-col>
            <b-col sm="12" md="8" lg="7" class="accounts_wrapper pt-2 pb-3 px-2 py-md-5 pl-md-5 pr-md-0 py-lg-5 px-lg-5">
              <h4>{{ $t(translationPath + 'subheading_text') }}</h4>
              <div class="confirm_text">

                <!-- If there already exists a previous balance for the account -->
                <template v-if="displayAmountExistsAlert">
                  <b-alert variant="success" show>
                    <p><i18n :path="translationPath + 'amount_exists'" tag="span">
                      <b slot="amount">{{ initialAmount }}</b>
                      <b slot="date">{{ displayDate }}</b>
                      <template slot="changeamount">{{ displayAmount }}</template>
                    </i18n>
                    </p>
                    <i18n v-if="offsetAccountIsNotSystem" tag="p" :path="translationPath + 'amount_exists_default_account'">
                      <b slot="default_offset_account_name">{{offsetAccounName}}</b>
                    </i18n>
                  </b-alert>
                </template>
                <template v-else>
                <!-- if no pre-existing amount is set (we are setting the start balance of the account -->
                  <b-alert variant="success" show :v-if="!displayAmountExistsAlert">
                    <p>
                      <i18n v-show="!displayAmountExistsAlert" :path="translationPath + 'amount_not_exists_1'" tag="span">
                        <b slot="amount">{{ initialAmount }}</b>
                        <b slot="date">{{ displayDate }}</b>
                      </i18n>
                    </p>
                    <i18n v-if="offsetAccountIsNotSystem" tag="p" :path="translationPath + 'amount_exists_default_account'">
                      <b slot="default_offset_account_name">{{offsetAccounName}}</b>
                    </i18n>
                  </b-alert>

                  <p v-show="!displayAmountExistsAlert">
                    <i18n :path="translationPath + 'amount_not_exists_2'" tag="small" v-show="!displayAmountExistsAlert">
                      <template slot="date">{{ displayDate }}</template>
                      <b-link slot="help_link" target="_blank" href="https://forum.spirecta.se/t/overkurs-tillgangar-sa-beraknas-och-korrigeras-vardet-pa-en-tillgang/40">{{ $t(translationPath + 'help_link') }}</b-link>
                    </i18n>
                  </p>
                </template>

                <!--Advanced Toggle-->
                <b-form-group
                  :label-class="'pr-4 text-black pt-2'"
                  label-cols-sm="12"
                  label-cols-md="12"
                  label-cols-lg="5"
                  label-cols-xl="5"
                  label-align-lg="left"
                  :label="$t(translationPathCommon + 'toggle_advanced')"
                >
                  <b-form-checkbox v-model="showOptions" name="toggle-button" size="lg" v-bind:class="currentLang === 'sv' ? 'pt-1' : 'pt-2'" switch>
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <template v-if="showOptions">
                <hr/>

                <h4>{{ $t(translationPath + 'label_subheading') }}</h4>
                <i18n :path="translationPath + 'amount_exists_contd'" tag="p">
                  <b-link slot="help_link" target="_blank" href="https://forum.spirecta.se/t/motkonto-och-introduktion-till-klassisk-bokforing/85">{{ $t(translationPath + 'help_link') }}</b-link>
                </i18n>
                <b-form-group
                  :label="offsetLabel"
                  label-cols-sm="12"
                  label-cols-md="12"
                  label-cols-lg="5"
                  label-cols-xl="5"
                  :label-class="this.AccountType === 'asset' ? (langSelected !== 'sv' ? 'pt-1' : 'pt-3'): 'pt-3'"
                  :state="offsetAccountState"
                  :invalid-feedback="invalidoffsetAccountFeedback"
                >
                  <template slot="description" class="offsetError" v-if="!offsetAccount && !currentCOA.networth_account_id">{{ $t(translationPath + 'offset_error') }} <b-link target="_blank" href="javascript:void(0);" :data-beacon-article-sidebar="offsetAccounHelp">{{ $t(translationPath + 'offset_error_help') }}</b-link></template>
                  <custom-select
                    v-model="offsetAccount"
                    :options="offsetAcountsOptions"
                    groupingValues="accounts"
                    groupingLabel="label"
                    :sortGroupLabel="false"
                    :placeholder="offsetPlaceholder"
                    :search-placeholder="$t('common.type_to_filter')"
                    :noResultText="$t('common.no_option_found')"
                    v-bind:class="{'is-invalid': offsetAccountState === false}"
                    label="label"
                  >
                  </custom-select>
                </b-form-group>

                <template v-if="percentage !== null">
                  <b-form-group
                    label-for="account_percentage"
                    label-class="customPadding"
                    label-cols-sm="12"
                    label-cols-md="12"
                    label-cols-lg="5"
                    label-cols-xl="5"
                    label-align-sm="left"
                  >
                    <template slot="label">{{$t('common.percentage')}}</template>
                    <template slot="description">{{ $t(translationPath + 'percentage_help_text' , {active: percentage, passive: passivePercentageValue })}}</template>
                    <b-col sm="12" md="9" class="float-left paddingLeftHide mobilePaddingLR-0 mb-2 mb-md-0 pb-2 pt-2">
                      <b-form-input
                        id="account_percentage_slider"
                        class="slider"
                        type="range"
                        min="0"
                        max="100"
                        step="5"
                        v-model="percentage"
                      ></b-form-input>
                    </b-col>
                    <b-col sm="12" md="3" class="float-left mb-2 paddingLeftHide mobilePaddingLR-0 pr-0">
                      <b-form-input
                        id="account_percentage"
                        type="number"
                        min="0"
                        max="100"
                        v-model="percentage"
                        :disabled="false"
                      ></b-form-input>
                    </b-col>
                  </b-form-group>
                </template>
              </template>
            </b-col>
          </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import displayAccountTitle from '@/assets/filters/displayAccountTitle'
import CustomSelect from '@/components/common/CustomSelect'
import _ from 'lodash'

export default {
  name: 'UpdateValueWizardStep02',
  props: {
    Initial: Object,
    AccountType: String,
    Balance: String,
    defaultOffsetAccount: Number,
    AccountId: Number
  },
  components: { CustomSelect },
  data () {
    return {
      date: null,
      title: null,
      decription: null,
      exists: false,
      previous_amount: 0,
      offsetAcountsOptions: [],
      offsetAccount: null,
      offsetAccounName: null,
      percentage: null,
      submitted: false,
      showOptions: false,
      translationPath: 'assets_liabilities.update_value_wizard.step2.',
      translationPathCommon: 'assets_liabilities.update_value_wizard.common.'
    }
  },
  created () {
    this.fetchAllAccountGroups()
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    currentLang () {
      return this.$i18n.locale
    },
    invalidoffsetAccountFeedback () {
      if (this.submitted === true) {
        if (!this.offsetAccount) {
          return this.currentUser.strict_accounting_mode ? this.$t(this.translationPath + 'form.errors.select_account') : this.$t(this.translationPath + 'form.errors.select_category')
        } else {
          return ''
        }
      }
      return ''
    },
    offsetPlaceholder () {
      return this.currentUser.strict_accounting_mode ? this.$t(this.translationPath + 'form.select_account_placeholder') : this.$t(this.translationPath + 'form.select_category_placeholder')
    },
    offsetAccountState () {
      if (this.submitted === true) {
        if (this.offsetAccount) {
          return true
        }
        return false
      }
      return null
    },
    displayDate () {
      return this.Initial && Object.prototype.hasOwnProperty.call(this.Initial, 'date') ? this.$moment(this.Initial.date).format('YYYY-MM-DD') : null
    },
    offsetLabel () {
      return this.AccountType === 'asset' ? this.$t(this.translationPath + 'assets_select_label') : this.$t(this.translationPath + 'liability_select_label')
    },
    amount () {
      return this.Initial && Object.prototype.hasOwnProperty.call(this.Initial, 'amount') ? (this.previous_amount && this.previous_amount !== 0 ? parseFloat(Math.abs(this.previous_amount) > Math.abs(this.Initial.amount) ? Math.abs(this.previous_amount) : Math.abs(this.Initial.amount)) - parseFloat(Math.abs(this.previous_amount) > Math.abs(this.Initial.amount) ? Math.abs(this.Initial.amount) : Math.abs(this.previous_amount)) : this.Initial.amount) : 0
    },
    initialAmount () {
      return this.Initial && Object.prototype.hasOwnProperty.call(this.Initial, 'amount') ? this.$options.filters.formatAmount(this.Initial.amount, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso) : this.$options.filters.formatAmount(0, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso)
    },
    displayAmount () {
      const initialAmount = this.Initial && Object.prototype.hasOwnProperty.call(this.Initial, 'amount') ? this.Initial.amount : 0
      const calculateDifference = this.previous_amount && this.previous_amount !== 0 ? parseFloat(initialAmount) - parseFloat(this.previous_amount) : initialAmount
      return calculateDifference ? this.$options.filters.formatAmount(calculateDifference, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso) : this.$options.filters.formatAmount(0, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso)
      // return this.Initial && Object.prototype.hasOwnProperty.call(this.Initial, 'amount') ? (calculateDifference < 0 ? ' - ' : '') + this.$options.filters.formatAmount(this.amount, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso) : this.$options.filters.formatAmount(0, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso)
    },
    displayAccountType () {
      return this.AccountType ? this.AccountType : null
    },
    displayAccountGroupName () {
      return this.Initial && Object.prototype.hasOwnProperty.call(this.Initial, 'account_group_title') ? this.Initial.account_group_title : null
    },
    langSelected () {
      return this.$i18n.locale
    },
    displayAmountExistsAlert () {
      return this.previous_amount !== 0
    },
    passivePercentageValue () {
      return 100 - this.percentage
    },
    offsetAccountIsNotSystem () {
      if (!this.offsetAccount || (this.offsetAccount && this.offsetAccount.id === this.currentCOA.networth_account_id)) {
        return false
      }
      return true
    }
  },
  methods: {
    reset () {
      this.fetchAllAccountGroups()
      this.submitted = false
    },
    fetchAllAccountGroups () {
      try {
        const accountGroups = this.AccountType === 'asset' ? 'income,expense,asset,liability' : 'income,expense,asset,liability'
        axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=${accountGroups}&include_networth_account=1&log=UpdateValueWizardStep02`)
          .then(response => response.data.data)
          .then(res => {
            const incomeGroups = res.filter(item => item.type === 'income').map(items => {
              const label = (this.$te(items.type) ? this.$t(items.type) : items.type) + ' / ' + items.title
              const accounts = items.accounts.filter(accItem => accItem.id !== Number(this.AccountId)).map(accountItem => {
                const accountLabel = displayAccountTitle(accountItem.title, accountItem.code, this.currentCOA.prefix_account_title_with_code)
                return Object.assign(accountItem, { label: accountLabel })
              })
              return Object.assign({}, items, { accounts: accounts, label: label })
            })
            const expenseGroups = res.filter(item => item.type === 'expense').map(items => {
              const label = (this.$te(items.type) ? this.$t(items.type) : items.type) + ' / ' + items.title
              const accounts = items.accounts.filter(accItem => accItem.id !== Number(this.AccountId)).map(accountItem => {
                const accountLabel = displayAccountTitle(accountItem.title, accountItem.code, this.currentCOA.prefix_account_title_with_code)
                return Object.assign(accountItem, { label: accountLabel })
              })
              return Object.assign({}, items, { accounts: accounts, label: label })
            })
            const assetsGroups = res.filter(item => item.type === 'asset').map(items => {
              const label = (this.$te(items.type) ? this.$t(items.type) : items.type) + ' / ' + items.title
              const accounts = items.accounts.filter(accItem => accItem.id !== Number(this.AccountId)).map(accountItem => {
                const accountLabel = displayAccountTitle(accountItem.title, accountItem.code, this.currentCOA.prefix_account_title_with_code)
                return Object.assign(accountItem, { label: accountLabel })
              })
              return Object.assign({}, items, { accounts: accounts, label: label })
            })
            const liabilityGroups = res.filter(item => item.type === 'liability').map(items => {
              const label = (this.$te(items.type) ? this.$t(items.type) : items.type) + ' / ' + items.title
              const accounts = items.accounts.filter(accItem => accItem.id !== Number(this.AccountId)).map(accountItem => {
                const accountLabel = displayAccountTitle(accountItem.title, accountItem.code, this.currentCOA.prefix_account_title_with_code)
                return Object.assign(accountItem, { label: accountLabel })
              })
              return Object.assign({}, items, { accounts: accounts, label: label })
            })
            this.offsetAcountsOptions = [...incomeGroups, ...expenseGroups, ...assetsGroups, ...liabilityGroups].filter(items => items.accounts.length !== 0)
            /* if (this.exists === false) {
              let currentCoaAccount = this.currentCOA.networth_account_id ? Number(this.currentCOA.networth_account_id) : null
              let selectedOffsetName = currentCoaAccount ? _.flattenDeep(this.offsetAcountsOptions.map(item => item.accounts)).filter(item => item.id === currentCoaAccount) : []
              this.offsetAccount = selectedOffsetName.length > 0 ? selectedOffsetName[0] : null
            } else {
              let checkoffsetvalue = this.defaultOffsetAccount > 0 ? _.flattenDeep(this.offsetAcountsOptions.map(item => item.accounts)).filter(item => item.id === this.defaultOffsetAccount) : []
              this.offsetAccount = checkoffsetvalue.length > 0 ? checkoffsetvalue[0] : null
            } */
            if (this.defaultOffsetAccount && this.exists === true) {
              const checkoffsetvalue = this.defaultOffsetAccount > 0 ? _.flattenDeep(this.offsetAcountsOptions.map(item => item.accounts)).filter(item => item.id === this.defaultOffsetAccount) : []
              let offsetAccount = checkoffsetvalue.length > 0 ? checkoffsetvalue[0] : null
              if (!offsetAccount) {
                const currentCoaAccount = this.currentCOA.networth_account_id ? Number(this.currentCOA.networth_account_id) : null
                const selectedOffsetName = currentCoaAccount ? _.flattenDeep(this.offsetAcountsOptions.map(item => item.accounts)).filter(item => item.id === currentCoaAccount) : []
                offsetAccount = selectedOffsetName.length > 0 ? selectedOffsetName[0] : null
              }
              this.offsetAccount = offsetAccount
            } else {
              const currentCoaAccount = this.currentCOA.networth_account_id ? Number(this.currentCOA.networth_account_id) : null
              const selectedOffsetName = currentCoaAccount ? _.flattenDeep(this.offsetAcountsOptions.map(item => item.accounts)).filter(item => item.id === currentCoaAccount) : []
              this.offsetAccount = selectedOffsetName.length > 0 ? selectedOffsetName[0] : null
            }
          })
      } catch (e) {
        console.error('Message:', e.message)
      }
    },
    async validate () {
      this.submitted = true
      // let offsetAccounts = this.exists === false ? this.currentCOA.networth_account_id : this.offsetAccount
      const offsetAccounts = this.offsetAccount
      if (!offsetAccounts) {
        if (!this.currentCOA.networth_account_id) {
          this.$bvToast.toast(this.$t(this.translationPath + 'notification_text'), {
            title: this.$t(this.translationPath + 'notification_heading'),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        }
        return false
      }
      this.$emit('validate-success', {
        TransactionAmount: this.Initial.amount,
        AmountExists: this.exists,
        PreviousAmount: this.previous_amount,
        accountOffset: this.offsetAccount.id,
        percentage: this.percentage
      })
      return true
    },
    fetchChartAccount () {
      return axios.get(process.env.VUE_APP_ROOT_API + '/accounts')
        .then(response => response.data.data)
        .then(data => {
          return data
        })
        .catch(err => {
          console.error(err)
          return []
        })
    }
  },
  watch: {
    Balance: function (newVal) {
      if (newVal) {
        this.exists = Number(newVal) !== 0
        this.previous_amount = Number(newVal)
      }
    },
    offsetAccount: function (newVal) {
      if (newVal) {
        if (['income', 'expense'].indexOf(newVal.type) !== -1) {
          this.percentage = newVal.default_active_percentage ? newVal.default_active_percentage : 0
        } else {
          this.percentage = null
        }

        const selectedOffsetName = newVal
        this.offsetAccounName = selectedOffsetName && Object.keys(selectedOffsetName).length > 0 ? displayAccountTitle(selectedOffsetName.title, selectedOffsetName.code, this.currentCOA.prefix_account_title_with_code) : null
      }
    },
    currentCOA: function (newVal) {
      if (newVal) {
        this.offsetAcountsOptions = this.offsetAcountsOptions.map(items => {
          const label = items.title
          const accounts = items.accounts.filter(accItem => accItem.id !== Number(this.AccountId)).map(accountItem => {
            const accountLabel = displayAccountTitle(accountItem.title, accountItem.code, this.currentCOA.prefix_account_title_with_code)
            return Object.assign(accountItem, { label: accountLabel })
          })
          return Object.assign({}, items, { accounts: accounts, label: label })
        })
      }
    },
    AccountId: function (newVal) {
      if (newVal) {
        this.fetchAllAccountGroups()
      }
    }
  },
  filters: { formatAmount, displayAccountTitle }
}
</script>

<style lang="scss">
.UpdateValueWizardStep02 {
  .confirm_text {
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  #emberSelect {
    display: grid;
  }
  .form-group {
    .col {
      @media screen and (max-width:767.98px) {
        padding-left: 5px;
      }
    }
  }
  .accounts_wrapper {
    border-left: 1px solid #D7D7D7;
    @media screen and (max-width:767.98px) {
      border-left: none;
    }
  }
}
</style>
