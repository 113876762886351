<template>
    <div class="updateWizard">
        <b-container class="account-wizard bg-light page-wrapper">
            <b-row class="main-content-wrapper" no-gutters>
                <b-col>
                    <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
                    <!---<h1>{{ $t('assets_liabilities.submenu.assets_and_liabilities') }}</h1>-->
                    <h1 class="text-left">{{ head }}</h1>
                    <div class="step-container-v2">
                        <div class="stepper bg-light mx-auto text-regular">
                            <form-wizard title="" :subtitle="''" :stepSize="'xs'" :color="'#ffffff'"  :errorColor="'#ffffff'" ref="step" :nextButtonText="$t('common.next')" :backButtonText="$t('common.previous')" :finishButtonText="$t('common.finish')" @on-complete="postDetails">
                                <tab-content :title="$t(translationPath + 'amount')" :before-change="()=>validate('step1')">
                                    <update-value-wizard-step01 ref="step1" :TransactionTitle="transactionTitle" :Balance="balance" @validate-success="updateFinalObject" :selectedId="id" @change-heading="changeHeading"></update-value-wizard-step01>
                                </tab-content>
                                <tab-content :title="$t(translationPath + 'confirm')" :before-change="()=>validate('step2')">
                                    <update-value-wizard-step02 ref="step2" :Balance="balance" :defaultOffsetAccount="defaultOffsetAccount" :AccountId="selectedAccountId" :AccountType="account_type" @validate-success="updateFinalObject" :Initial="postObject"></update-value-wizard-step02>
                                </tab-content>
                            </form-wizard>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <update-value-success
                :title="$t(translationPathSuccess + account_type + '_title', { name: valueTitle })"
                :messagePath="translationPathSuccess + (account_type + '_message')"
                :value="Object.keys(postObject).length > 0 ? postObject.amount : null"
                :currencyISO="currentCOA.currency_iso"
                :transactionId="transactionId"
                :button="$t(translationPathSuccess + account_type + '_button')"
                :redirect="(account_type === 'asset' ? '/reports/other/account-plan-v2/assets/values' : '/reports/other/account-plan-v2/liabilities/values')"
                :orLink="'/reports/performance/accounts/'+ id +'/view'"
                :orLinkText="$t(translationPathSuccess + account_type + '_orLinkText')"
            ></update-value-success>
        </b-container>
    </div>
</template>

<script>
import UpdateValueWizardStep01 from './wizard-steps/UpdateValueWizardStep01'
import UpdateValueWizardStep02 from './wizard-steps/UpdateValueWizardStep02'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import UpdateValueSuccess from './UpdateValueSuccess'
import displayAccountTitle from '@/assets/filters/displayAccountTitle'
import axios from 'axios'
import titleMixins from '@/mixins/title'
import { mapState } from 'vuex'

export default {
  name: 'UpdateValue',
  mixins: [titleMixins],
  components: {
    UpdateValueSuccess,
    FormWizard,
    TabContent,
    UpdateValueWizardStep01,
    UpdateValueWizardStep02
  },
  filters: { displayAccountTitle },
  data () {
    return {
      id: null,
      headingTitle: null,
      defaultTitleValue: null,
      account_type: null,
      postObject: {},
      transactions: [],
      defaultOffsetAccount: null,
      transactionId: null,
      balance: null,
      translationPath: 'assets_liabilities.update_value_wizard.common.',
      translationPathSuccess: 'assets_liabilities.update_value_wizard.success_modal.'
    }
  },
  async created () {
    this.id = Number(this.$route.params.id)
    this.account_type = this.$route.params.account_type
    if (['asset', 'liability'].indexOf(this.account_type) === -1) {
      this.$router.push('/404')
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    selectedAccountId () {
      const postObjectKeys = Object.keys(this.postObject)
      return postObjectKeys.length > 0 && postObjectKeys.indexOf('account_id') > -1 ? this.postObject.account_id : null
    },
    head () {
      return this.headingTitle ? this.$t(this.translationPath + 'update_value', { account: this.headingTitle }) : this.$t(this.translationPath + 'update_value', { account: null })
    },
    valueTitle () {
      return this.headingTitle ? this.headingTitle : null
    },
    breadcrumbItems () {
      const aBreadCrumb = [
        { text: this.$t('common.header.header_menu.manage_title'), to: '/reports/other/account-plan-v2/assets' },
        { text: this.$t('assets_liabilities.create_account_wizard.common.' + (this.$route.params.account_type).toLowerCase()), to: '/reports/other/account-plan-v2/assets' },
        { text: '', active: true }
      ]
      return aBreadCrumb
    },
    transactionTitle () {
      if (this.transactions.length > 0) {
        return this.$t(this.translationPath + 'revaluation', { account: this.defaultTitleValue })
      } else {
        return this.$t(this.translationPath + 'starting_value', { account: this.defaultTitleValue })
      }
    },
    finalObject () {
      if (Object.keys(this.postObject).length > 0 && Object.keys(this.postObject).indexOf('AmountExists') > -1) {
        if (this.postObject.AmountExists) {
          const calculateDifference = parseFloat(this.postObject.TransactionAmount) - parseFloat(this.balance)
          if (calculateDifference < 0) {
            return [
              {
                title: this.postObject.title,
                date: this.getFormattedDate(this.postObject.date),
                type: 'transfer',
                description: this.postObject.description,
                entries: [
                  {
                    transaction_id: 0,
                    account_id: this.postObject.account_id ? Number(this.postObject.account_id) : 0,
                    offset_account_id: this.postObject.accountOffset ? Number(this.postObject.accountOffset) : 0,
                    dctype: this.account_type === 'asset' ? 'cr' : 'dr',
                    amount: Math.abs(calculateDifference),
                    active_percentage: this.postObject.percentage
                  },
                  {
                    transaction_id: 0,
                    account_id: this.postObject.accountOffset ? Number(this.postObject.accountOffset) : 0,
                    offset_account_id: this.postObject.account_id ? Number(this.postObject.account_id) : 0,
                    dctype: this.account_type === 'asset' ? 'dr' : 'cr',
                    amount: Math.abs(calculateDifference),
                    active_percentage: this.postObject.percentage
                  }
                ]
              }
            ]
          } else {
            return [
              {
                title: this.postObject.title,
                date: this.getFormattedDate(this.postObject.date),
                type: 'transfer',
                description: this.postObject.description,
                entries: [
                  {
                    transaction_id: 0,
                    account_id: this.postObject.account_id ? Number(this.postObject.account_id) : 0,
                    offset_account_id: this.postObject.accountOffset ? Number(this.postObject.accountOffset) : 0,
                    dctype: this.account_type === 'asset' ? 'dr' : 'cr',
                    amount: Math.abs(calculateDifference),
                    active_percentage: this.postObject.percentage
                  },
                  {
                    transaction_id: 0,
                    account_id: this.postObject.accountOffset ? Number(this.postObject.accountOffset) : 0,
                    offset_account_id: this.postObject.account_id ? Number(this.postObject.account_id) : 0,
                    dctype: this.account_type === 'asset' ? 'cr' : 'dr',
                    amount: Math.abs(calculateDifference),
                    active_percentage: this.postObject.percentage
                  }
                ]
              }
            ]
          }
        } else {
          return [
            {
              title: this.postObject.title,
              date: this.getFormattedDate(this.postObject.date),
              type: 'transfer',
              description: this.postObject.description,
              entries: [
                {
                  transaction_id: 0,
                  account_id: this.postObject.account_id ? Number(this.postObject.account_id) : 0,
                  offset_account_id: this.postObject.accountOffset ? Number(this.postObject.accountOffset) : 0,
                  dctype: this.account_type === 'asset' ? 'dr' : 'cr',
                  amount: this.postObject.TransactionAmount,
                  active_percentage: this.postObject.percentage
                },
                {
                  transaction_id: 0,
                  account_id: this.postObject.accountOffset ? Number(this.postObject.accountOffset) : 0,
                  offset_account_id: this.postObject.account_id ? Number(this.postObject.account_id) : 0,
                  dctype: this.account_type === 'asset' ? 'cr' : 'dr',
                  amount: this.postObject.TransactionAmount,
                  active_percentage: this.postObject.percentage
                }
              ]
            }
          ]
        }
      }
      return null
    }
  },
  methods: {
    async validate (reference) {
      return this.$refs[reference].validate()
    },
    changeHeading (value) {
      if (value.id) {
        this.id = value.id
        this.$refs.step1.reset()
        this.$refs.step2.reset()
      }
    },
    postDetails () {
      if (this.finalObject) {
        const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/transactions/with/entries'
        axios({
          method: 'post',
          url: sApiEndpoint,
          data: {
            transactions: this.finalObject
          }
        })
          .then(data => {
            this.transactionId = data.data.data[0].id
            this.$bvModal.show('UpdateValueSuccess')
            this.$refs.step.reset()
            this.$refs.step1.reset()
            this.$refs.step2.reset()
            this.fetchAccountTransactions(this.id, this.account_type).then(transactionalData => {
              this.transactions = transactionalData
              return transactionalData
            }).then(resp => {
              this.fetchAccountDetails(this.id).then(accountDetails => {
                this.headingTitle = Object.prototype.hasOwnProperty.call(accountDetails, 'title') ? accountDetails.title : null
                this.balance = Object.prototype.hasOwnProperty.call(accountDetails, 'amount') ? accountDetails.amount : '0'
              })
            })
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    updateFinalObject (value) {
      this.postObject = Object.assign({}, this.postObject, value)
    },
    getFormattedDate (iDate) {
      const oDate = new Date(iDate)
      let sMonth = oDate.getMonth() + 1
      if (sMonth < 10) {
        sMonth = '0' + sMonth
      }
      let sDay = oDate.getDate()
      if (sDay < 10) {
        sDay = '0' + sDay
      }
      return oDate.getFullYear() + '-' + sMonth + '-' + sDay
    },
    fetchAccountDetails (accountId) {
      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/${accountId}?fetch_amount=1`)
        .then(response => response.data.data)
        .then(data => {
          return data
        })
        .catch(err => {
          console.error(err)
          return {}
        })
    },
    fetchAccountTransactions (accountId, accountType) {
      return axios.get(`${process.env.VUE_APP_ROOT_API}/transactions?account_id=${accountId}&account_type=${accountType}&limit=10000`)
        .then(response => response.data.data)
        .then(data => {
          return data
        })
        .catch(err => {
          console.error(err)
          return []
        })
    }
  },
  watch: {
    head: {
      handler () {
        this.setPageTitle(this.head)
      },
      immediate: true
    },
    id: function (newVal) {
      if (newVal) {
        const promiseArray = [
          this.fetchAccountDetails(newVal),
          this.fetchAccountTransactions(newVal, this.account_type)
        ]
        Promise.all(promiseArray)
          .then(result => {
            const accountDetails = result[0]
            this.transactions = result[1]
            this.headingTitle = Object.prototype.hasOwnProperty.call(accountDetails, 'title') ? displayAccountTitle(accountDetails.title, accountDetails.code, this.currentCOA.prefix_account_title_with_code) : null
            this.defaultTitleValue = Object.prototype.hasOwnProperty.call(accountDetails, 'title') ? accountDetails.title : null
            this.balance = Object.prototype.hasOwnProperty.call(accountDetails, 'amount') ? accountDetails.amount : '0'
            this.defaultOffsetAccount = Object.prototype.hasOwnProperty.call(accountDetails, 'default_offset_account_id') ? accountDetails.default_offset_account_id : 0
          })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/form-wizard-2.scss';
@import '@/assets/scss/modal.scss';
.updateWizard {
  width: 100%;
  display: flex;
  .vue-form-wizard {
    padding-bottom: 0;
  }
  label {
    padding-top: 14px;
  }
  legend {
    padding-top: 14px;
  }
}
</style>
