<template>
    <div class="UpdateValueWizardStep01">
        <b-container class="import-wizard-step01">
          <b-row no-gutters>
            <b-col cols="12" lg="4" class="sidebar-text detail-text text-left pt-3 pb-1 px-2 pt-md-0 pb-md-2 px-md-2 py-lg-5 px-lg-5">
              <h2 class="text-dark">{{ $t(this.translationPath + 'title') }} </h2>
              <div v-html="$t(translationPath + 'description')"></div>
            </b-col>
            <b-col sm="12" md="8" lg="7" class="accounts_wrapper py-2 px-2 py-md-5 pl-md-5 pr-md-0 py-lg-5 px-lg-5">
              <b-form-group
                label-class="pr-4 text-black"
                label-for="accountId"
                label-cols-lg="4"
                label-align-lg="right"
                :label="accountIdLabel"
                :state="accountIdState"
                :invalid-feedback="invalidAccountIdFeedback"
              >
                <custom-select
                  v-model="accountId"
                  :options="accountGroupOptions"
                  grouping-label="label"
                  grouping-values="accounts"
                  label="label"
                  :sortGroupLabel="false"
                  :placeholder="$t(translationPathCommon + 'please_select_account')"
                  :search-placeholder="$t('common.type_to_filter')"
                  :noResultText="$t('common.no_option_found')"
                  v-bind:class="{'is-invalid': accountIdState === false, 'is-valid': accountIdState === true}"
                >
                </custom-select>
              </b-form-group>
              <b-form-group
                label-class="pr-4 text-black"
                label-for="date"
                label-cols-lg="4"
                label-align-lg="right"
                :label="$t(translationPath + 'form.labels.select_date_for_the_value')"
                :state="dateState"
                :invalid-feedback="invalidDateFeedback"
                :description="$t(translationPath + 'form.labels.select_date_for_the_value_description')"
              >
                <datepicker v-model="date" :language="datepickerLanguage" :typeable='true' placeholder="YYYY-MM-DD" :format="dateFormatter" :state="dateState" v-bind:input-class="{'form-control': dateState === null, 'form-control is-invalid': dateState === false, 'form-control is-valid': dateState === true }" :monday-first="startWeekByMonday"></datepicker>
              </b-form-group>
              <b-form-group
                label-class="pr-4 text-black"
                label-for="amount"
                label-cols-lg="4"
                label-align-lg="right"
                :label="$t(translationPath + 'form.labels.set_current_value')"
                :state="amountState"
                :invalid-feedback="invalidAmountFeedback"
              >
                <template slot="description">{{ $t(translationPath + 'form.labels.set_current_value_description_' + $route.params.account_type, {date: date.toISOString().split('T')[0]}) }}</template>
                <currency-input
                  ref="CurrencyInputAmount"
                  v-model="amount"
                  v-bind="options"
                  :input-class="{'is-invalid': amountState === false, 'is-valid': amountState === true }"
                  :state="amountState"
                  :precision="2"
                />
              </b-form-group>

              <b-form-group
                label-class="pr-4 text-black"
                label-for="title"
                label-cols-lg="4"
                label-align-lg="right"
                :label="$t(translationPath + 'form.labels.transaction_title')"
                :state="titleState"
                :invalid-feedback="invalidTitleFeedback"
              >
                <template slot="description">{{ $t(translationPath + 'form.labels.transaction_title_description') }}</template>
                <b-form-input v-model="title" :state="titleState" placeholder="Enter transaction title"></b-form-input>
              </b-form-group>
              <b-form-group
                label-class="pr-4 text-black"
                label-for="description"
                label-cols-lg="4"
                label-align-lg="right"
                :label="$t(translationPath + 'form.labels.additional_information')"
              >
                <b-form-textarea v-model="description" rows="3"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
    </div>
</template>

<script>
import CustomSelect from '@/components/common/CustomSelect'
import CurrencyInput from '@/components/common/CurrencyInput'
import useComponentCurrencyInputMixin from '@/mixins/useComponentCurrencyInput'
import Datepicker from 'vuejs-datepicker'
import { en, da, sv } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import displayAccountTitle from '@/assets/filters/displayAccountTitle'
import _ from 'lodash'
// import formatDate from '@/assets/filters/formatDate'
// import formatAmount from '@/assets/filters/formatAmount'
import axios from 'axios'
import { mapState } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
const ValidDateFormate = (date) => {
  return moment(date, 'YYYY-MM-DD', true).isValid()
}
const validAmount = (value, vm) => {
  return value === 0 ? true : value !== vm.balance
}
export default {
  name: 'UpdateValueWizardStep01',
  mixins: [useComponentCurrencyInputMixin],
  components: { CurrencyInput, Datepicker, CustomSelect },
  props: {
    AccountId: Number,
    Initial: Object,
    selectedId: Number,
    Balance: String,
    TransactionTitle: String
  },
  data () {
    return {
      startWeekByMonday: Object.prototype.hasOwnProperty.call(process.env, 'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY') && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true' : false,
      accountId: null,
      amount: 0,
      date: new Date(),
      title: null,
      balance: 0,
      description: null,
      accountOptions: [],
      translationPath: 'assets_liabilities.update_value_wizard.step1.',
      translationPathCommon: 'assets_liabilities.update_value_wizard.common.',
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      },
      allowNegative: true,
      valueAsInteger: false,
      validGroups: ['asset', 'liability']
    }
  },
  validations: {
    accountId: {
      required
    },
    title: {
      required,
      minLength: minLength(4)
    },
    balance: {
      required
    },
    amount: {
      required,
      ValidAmount: validAmount
    },
    date: {
      required,
      validFormate: ValidDateFormate
    },
    validationGroup: ['accountId', 'title', 'amount', 'balance', 'date']
  },
  async created () {
    await this.getAccountGroups().then(data => {
      this.accountOptions = data
      const selectedAccount = this.selectedId ? _.flattenDeep(data.map(item => item.accounts)).filter(item => item.id === this.selectedId) : []
      this.accountId = selectedAccount.length > 0 ? selectedAccount[0] : null
    })
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    accountGroupOptions () {
      return this.accountOptions.map(item => {
        const accountGroupTitle = item.title
        const groupAccounts = item.accounts.map(accountsItems => {
          const groupTitle = displayAccountTitle(accountsItems.title, accountsItems.code, this.currentCOA.prefix_account_title_with_code)
          return Object.assign(accountsItems, {
            label: groupTitle
          })
        })
        return Object.assign(item, {
          label: accountGroupTitle,
          accounts: groupAccounts
        })
      })
    },
    options () {
      return {
        locale: this.currentCOA.locale ? this.currentCOA.locale : 'sv-SE',
        currency: this.currentCOA.currency_iso ? this.currentCOA.currency_iso : 'SEK',
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    },
    datepickerLanguage () {
      return this.datepickerLang[this.$i18n.locale]
    },
    accountIdLabel () {
      return this.$route.params.account_type === 'asset' ? this.$t(this.translationPath + 'form.labels.select_asset') : this.$t(this.translationPath + 'form.labels.select_liability')
    },
    titleState () {
      return this.$v.validationGroup.title.$dirty ? this.$v.validationGroup.title.required && this.$v.validationGroup.title.minLength : null
    },
    invalidTitleFeedback () {
      if (this.$v.validationGroup.title.$dirty) {
        if (!this.$v.validationGroup.title.required) {
          return this.$t(this.translationPath + 'form.errors.title_required')
        } else if (!this.$v.validationGroup.title.minLength) {
          return this.$t(this.translationPath + 'form.errors.title_less', { characters: 3 })
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    accountIdState () {
      return this.$v.validationGroup.accountId.$dirty ? this.$v.validationGroup.accountId.required : null
    },
    invalidAccountIdFeedback () {
      if (this.$v.validationGroup.accountId.$dirty) {
        if (!this.$v.validationGroup.accountId.required) {
          return this.$t(this.translationPath + 'form.errors.account_required')
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    amountState () {
      return this.$v.validationGroup.amount.$dirty ? (this.$v.validationGroup.amount.required && this.$v.validationGroup.amount.ValidAmount) : null
    },
    invalidAmountFeedback () {
      if (this.$v.validationGroup.amount.$dirty) {
        if (!this.$v.validationGroup.amount.required) {
          return this.$t(this.translationPath + 'form.errors.amount_required')
        } else if (!this.$v.validationGroup.amount.ValidAmount) {
          return this.amount && this.amount === this.balance ? this.$t(this.translationPath + 'form.errors.amount_valid_match') : this.$t(this.translationPath + 'form.errors.amount_valid')
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    dateState () {
      return this.$v.validationGroup.date.$dirty ? this.$v.validationGroup.date.required && this.$v.validationGroup.date.validFormate : null
    },
    invalidDateFeedback () {
      if (this.$v.validationGroup.date.$dirty) {
        if (!this.$v.validationGroup.date.required) {
          return this.$t(this.translationPath + 'form.errors.date_required')
        } else if (!this.$v.validationGroup.date.validFormate) {
          return this.$t(this.translationPath + 'form.errors.date_invalid')
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    dateFormatter (date) {
      const local = this.currentCOA.locale
      return this.$moment(date).locale(local).format('YYYY-MM-DD')
    },
    reset () {
      this.date = new Date()
      this.description = null
      this.$v.validationGroup.$reset()
    },
    async getAccountGroups () {
      let AccountType = null
      const paramAccounType = this.$route.params.account_type
      if (paramAccounType && this.validGroups.indexOf(paramAccounType) > -1) {
        AccountType = paramAccounType
      }
      const AccountGroups = await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=${AccountType}&log=UpdateWizardStep01`)
        .then(response => response.data.data)
        .then(data => {
          return data
        })
        .catch(err => {
          console.error(err)
          return []
        })
      return AccountGroups
    },
    async validate () {
      this.$v.validationGroup.$touch()
      const validFormStatus = !this.$v.validationGroup.$invalid
      if (validFormStatus) {
        const { amount, date, title, accountId, description } = this
        const findGroupTitle = this.accountOptions.filter(item => {
          const groupAccounts = item.accounts.filter(account => account.id === accountId)
          return groupAccounts.length > 0
        })
        const accounts = findGroupTitle.length > 0 ? findGroupTitle[0].accounts.filter(item => item.id === accountId) : null
        const accountGroupTitle = accounts ? (this.currentCOA && this.currentCOA.prefix_account_title_with_code ? accounts[0].code + ' - ' + accounts[0].title : accounts[0].title) : null
        this.$emit('validate-success', {
          amount,
          date,
          title,
          account_id: accountId.id,
          description,
          account_group_title: accountGroupTitle
        })
      }
      return validFormStatus
    }
  },
  watch: {
    Balance: function (newVal) {
      if (newVal) {
        this.amount = Number(newVal)
        this.balance = Number(newVal)
      }
    },
    TransactionTitle: function (newVal) {
      if (newVal) {
        this.title = newVal
      }
    },
    accountId: function (newVal) {
      if (newVal) {
        /* let findGroupTitle = this.accountOptions.filter(item => {
          let groupAccounts = item.accounts.filter(account => account.id === newVal)
          return groupAccounts.length > 0
        })
        let accounts = findGroupTitle.length > 0 ? findGroupTitle[0].accounts.filter(item => item.id === newVal) : null */
        const accountId = newVal ? newVal.id : null
        this.rerenderCurrencyInputs()
        this.$emit('change-heading', { id: accountId })
      }
    }
  },
  filters: { displayAccountTitle }
}
</script>

<style lang="scss">
.UpdateValueWizardStep01 {
  .form-group {
    .col {
      @media screen and (max-width:767.98px) {
        padding-left: 5px;
      }
    }
  }
  ul.taskLists {
    padding: 10px 15px;
    margin: 0;
    list-style: decimal;
    li {
      margin-bottom: 5px;
      font-weight: 200;
    }
  }
  .accounts_wrapper {
    border-left: 1px solid #D7D7D7;
    @media screen and (max-width:767.98px) {
      border-left: none;
    }
  }
}
</style>
