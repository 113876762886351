<template>
  <b-modal centered ref="UpdateValueSuccess" :no-close-on-backdrop="false" modal-class="updatevalue-success-modal" id="UpdateValueSuccess" size="lg" hide-header>
    <div class="message-container">
      <b-row>
        <b-col class="text-center">
          <b-img class="success-image" :src="require('@/assets/img/icon-done.png')"/>
        </b-col>
      </b-row>
      <div class="added-text">{{ title }}</div>
      <div class="added-description">
        <i18n :path="messagePath" tag="p">
          <template slot="value">{{ value }}</template>
          <template slot="currency_iso">{{ currencyISO }}</template>
          <b-link slot="here" :to="'/transactions/view/transaction/'+transactionId">{{ $t('common.here') }}</b-link>
        </i18n>
      </div>
    </div>
    <div slot="modal-footer" class="footer text-center justify-content-center align-items-center">
        <b-button class="btn btn-primary done-button" :to="redirect">{{ button }}</b-button>
        <b-button v-if="buttonSecondary" class="btn btn-secondary done-button" :to="redirectSecondary">{{ buttonSecondary }}</b-button>
        <br />
        <b-link :to="orLink" class="text-gray orLink" v-if="isOrLinkSupplied()">{{ orLinkText }}</b-link>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'UpdateValueSuccess',
  props: {
    title: String,
    messagePath: String,
    value: Number,
    currencyISO: String,
    transactionId: Number,
    button: String,
    buttonSecondary: String,
    redirect: String,
    redirectSecondary: String,
    orLink: String,
    orLinkText: String
  },
  methods: {
    isOrLinkSupplied () {
      return this.orLink
    }
  }
}
</script>

<style lang="scss">
  .updatevalue-success-modal {
    font-family: Roboto;

    .modal-content {
      border-radius: 0;
      padding: 0;
    }
    .modal-body {
      padding: 0px;
    }
    .footer {
      width: 100%;
      padding: 25px 0px;
      .done-button {
        border-radius: 20px;
        padding: 10px 25px 10px 25px;
        font-size:90%;
        background-color: #0065ff;
        border: none;
      }
      .btn-secondary {
        margin-left: 10px;
      }
      .orLink{
        font-size:90%;
        margin-top:5px;
      }
    }
    .message-container {
      flex-wrap: wrap;
      margin-right: -30px;
      margin-left: -30px;
      margin-top: 20px;
      background: #C0C0C0 url('~@/assets/img/success-modal-bg.jpg') repeat-y;
      padding-left: 12%;
      padding-right: 12%;
      padding-bottom: 6%;
      .success-image {
        width: 135px;
        margin-top: 6%;
        margin-bottom: 10px;
      }
      .added-text {
        color: #FFFFFF;
        font-size: 36px;
        line-height: 48px;
        text-align: center;
        margin-bottom: 10px;
      }
      .added-description {
        color: #FFFFFF;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
</style>
